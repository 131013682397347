


















import { Getter } from 'vuex-class';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { MediaHelper } from '@/mixins/MediaHelper';

interface ContactFormData {
    subject: string;
    message: string;
    attachment: File[];
}

interface ContactRequest {
    subject: string;
    message: string;
    email: string;
    locale: string;
    media?: ProductImage[];
}

@Component({
    components: {
        FormBase: () => import('@/components/forms/Base.vue'),
        FormInput: () => import('@/components/forms/Input.vue'),
        ElButton: () => import('@/components/elements/Button.vue'),
    },
})
export default class ContactForm extends Mixins(MediaHelper) {
    @Getter('user/user') user!: Auth;

    i18n: string = 'contact';

    success: boolean = false;

    form: ContactFormData = {
        subject: '',
        message: '',
        attachment: [],
    };

    $refs!: Vue['$refs'] & {
        contactForm: FormClass;
    };

    fileinputPlaceholder() {
        return `<span class="link">${this.$t(`${this.i18n}.form.attachment.upload_link`)}</span> ${this.$t(`${this.i18n}.form.attachment.upload_text`)}`;
    }

    contactSubmit() {
        const $form: FormClass = this.$refs.contactForm;

        const payload: ContactRequest = {
            subject: this.form.subject,
            message: this.form.message,
            email: this.user.email,
            locale: 'nl_BE',
        };

        this.prepareMediaArray(this.form.attachment)
            .then((response: ProductImage[]) => {
                if (response.length > 0) {
                    payload.media = response;
                }

                axios
                    .post(`${process.env.VUE_APP_API_URL}contact-requests`, payload, { withCredentials: true })
                    .then(() => {
                        $form.handleSuccess();
                        this.success = true;
                    })
                    .catch((e: ErrorResponse) => this.$refs.contactForm.handleErrors(e));
            })
            .catch((e: ErrorResponse) => {
                $form.handleSuccess();
            });
    }
}
